var site = site || {};
site.CheeseWheel = (function () {
    // Functions

    var fadeAndShow = function($){

        $(document).ready(function(){
            $('img.active').rwdImageMaps();
        })

        $(document).on('click', '.cheese-wheel-container map area', function(e){
            e.preventDefault();

            var imageID = $(this).attr('title').replace(" ", "").toLowerCase();

            $('.js-cw-img').removeClass('active');

            $("#"+imageID).addClass('active');
            
            $('img.active').rwdImageMaps();
            
        })
    }

    // Return
    return {
        fadeAndShow : fadeAndShow
    };
}());