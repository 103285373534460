var site = site || {};
site.StickyScrubVideo = (function () {
    // Functions

    // var scrubLactationVideo = function(){
    //     // select video elements
    //     const $videoContainer =  document.querySelector('.js-lact-video-container');
    //     const $video = document.getElementById('js-scrub-lact-video');

    //     if($video != null) {
    //         const $spacer = document.querySelector('.sticky-lact-video-height');

    //         let spacerHeight = $spacer.clientHeight;
    //         let viewportHeight = document.documentElement.clientHeight;
    //         let videoContainerLocation = $videoContainer.getBoundingClientRect().top + window.pageYOffset;

    //         let scrollableHeight = spacerHeight - viewportHeight;

    //         let currentTime = 0;

    //         window.addEventListener('scroll', function() {
    //             let videoLocation = $video.getBoundingClientRect();
    //             videoContainerLocation = $videoContainer.getBoundingClientRect().top + window.pageYOffset;
                
    //             if(videoLocation.top - 96 <= 0) {
    //                 currentTime = ((window.scrollY - videoContainerLocation) * $video.duration) / scrollableHeight;
    //                 $video.currentTime = currentTime;                    
    //             }
    //         });
    //     }
    // }

    // var scrubMilkVideo = function(){
    //     // select video elements
    //     const $videoContainer =  document.querySelector('.js-milk-video-container');
    //     const $video = document.getElementById('js-scrub-milk-video');

    //     if($video != null) {  
    //         const $spacer = document.querySelector('.sticky-milk-video-height');

    //         let spacerHeight = $spacer.clientHeight;
    //         let viewportHeight = document.documentElement.clientHeight;
    //         let videoContainerLocation = $videoContainer.getBoundingClientRect().top + window.pageYOffset;

    //         let scrollableHeight = spacerHeight - viewportHeight;

    //         let currentTime = 0;

    //         window.addEventListener('scroll', function() {
    //             let videoLocation = $video.getBoundingClientRect();
    //             videoContainerLocation = $videoContainer.getBoundingClientRect().top + window.pageYOffset;
                
    //             if(videoLocation.top - 96 <= 0) {
    //                 currentTime = ((window.scrollY - videoContainerLocation) * $video.duration) / scrollableHeight;
    //                 $video.currentTime = currentTime;                    
    //             }
    //         });
    //     }
    // }

    var scrubLactationSequencedImages = function(mediaQueries){
        if(mediaQueries.md.matches){
            if(document.body.contains(document.getElementById("js-lactation-cycle-sequence"))) {
                const canvas = document.getElementById("js-lactation-cycle-sequence");
                const context = canvas.getContext("2d");

                canvas.width=1280;
                canvas.height=926;

                const frameCount = 64;
                
                const currentFrame = index => (
                `/field-guide/dist/images/lactation-cycle/LactationCycle_${index.toString()}.jpg`
                )
        
                const images = {};
                
                let loadedImageCount = 0;

                for (let i = 1; i <= frameCount; i++) {
                    const img = new Image();
                    img.onload = function() {
                        if (++loadedImageCount === frameCount) {
                            context.drawImage(images[1], 0, 0);

                            window.addEventListener('scroll', () => {
                                const animationContainer = document.getElementById("js-sticky-lact-animation");
                                const canvasLocation = canvas.getBoundingClientRect();
            
                                if(Math.ceil(canvasLocation.top) <= 96) {
                                    const scrollFraction = Math.ceil(animationContainer.clientHeight - animationContainer.getBoundingClientRect().bottom);
                                    const frameIndex = Math.ceil(scrollFraction / 100);
                                    
                                    if(frameIndex <= 64 && frameIndex >= 1) {
                                        requestAnimationFrame(() => context.drawImage(images[frameIndex], 0, 0))
                                    }
                                }
                            });
                        }
                    };

                    img.src = currentFrame(i);
                    images[i] = img;
                }
            }
        }
    }

    var scrubAnatomyOfMilk1SequencedImages = function(mediaQueries){
        if(document.body.contains(document.getElementById("js-anatomy-of-milk-1-sequence"))) {
            let device, canvasWidth, canvasHeight;

            if(mediaQueries.sm.matches){
                device = "Desktop";
                canvasWidth = 1280;
                canvasHeight = 717;
            }else {
                device = "Mobile";
                canvasWidth = 480;
                canvasHeight = 856;
            }

            const canvas = document.getElementById("js-anatomy-of-milk-1-sequence");
            const context = canvas.getContext("2d");

            canvas.width=canvasWidth;
            canvas.height=canvasHeight;
    
            const frameCount = 78;
    
            const currentFrame = index => (
            `/field-guide/dist/images/anatomy-of-milk-${device}-part1/AnatomyofMilk-${device}-Part1-${index.toString()}.jpg`
            )

            const images = {};
                
            let loadedImageCount = 0;

            for (let i = 1; i <= frameCount; i++) {
                const img = new Image();
                img.onload = function() {
                    if (++loadedImageCount === frameCount) {
                        context.drawImage(images[1], 0, 0);

                        window.addEventListener('scroll', () => {
                            const animationContainer = document.getElementById("js-sticky-milk-1-animation");
                            const canvasLocation = canvas.getBoundingClientRect();
                
                            if(Math.ceil(canvasLocation.top) <= 144) {
                                const scrollFraction = Math.ceil(animationContainer.clientHeight - animationContainer.getBoundingClientRect().bottom);
                                const frameIndex = Math.ceil(scrollFraction / 100);
                                
                                if(frameIndex <= 78 && frameIndex >= 1) {
                                    requestAnimationFrame(() => context.drawImage(images[frameIndex], 0, 0))
                                }
                            }
                        });
                    }
                };

                img.src = currentFrame(i);
                images[i] = img;
            }
        }
    }

    var scrubAnatomyOfMilk2SequencedImages = function(mediaQueries){
        if(document.body.contains(document.getElementById("js-anatomy-of-milk-2-sequence"))) {
            let device, canvasWidth, canvasHeight;

            if(mediaQueries.sm.matches){
                device = "Desktop";
                canvasWidth = 1280;
                canvasHeight = 717;
            }else {
                device = "Mobile";
                canvasWidth = 480;
                canvasHeight = 833;
            }

            const canvas = document.getElementById("js-anatomy-of-milk-2-sequence");
            const context = canvas.getContext("2d");

            canvas.width=canvasWidth;
            canvas.height=canvasHeight;
    
            const frameCount = 64;

            const currentFrame = index => (
            `/field-guide/dist/images/anatomy-of-milk-${device}-part2/AnatomyofMilk-${device}-Part2-${index.toString()}.jpg`
            )

            const images = {};
                
            let loadedImageCount = 0;

            for (let i = 1; i <= frameCount; i++) {
                const img = new Image();
                img.onload = function() {
                    if (++loadedImageCount === frameCount) {
                        context.drawImage(images[1], 0, 0);

                        window.addEventListener('scroll', () => {
                            const animationContainer = document.getElementById("js-sticky-milk-2-animation");  
                            const canvasLocation = canvas.getBoundingClientRect();
                
                            if(Math.ceil(canvasLocation.top) <= 144) {
                                const scrollFraction = Math.ceil(animationContainer.clientHeight - animationContainer.getBoundingClientRect().bottom);
                                const frameIndex = Math.ceil(scrollFraction / 100);
                                
                                if(frameIndex <= 64 && frameIndex >= 1) {
                                    requestAnimationFrame(() => context.drawImage(images[frameIndex], 0, 0))
                                }
                            }
                        });
                    }
                };

                img.src = currentFrame(i);
                images[i] = img;
            }
        }
    }

    var scrubShelfSequencedImages = function(mediaQueries){
        if(document.body.contains(document.getElementById("js-shelf-sequence"))) {
            let device, canvasWidth, canvasHeight;

            if(mediaQueries.sm.matches){
                device = "Desktop";
                canvasWidth = 1280;
                canvasHeight = 991;
            }else {
                device = "Mobile";
                canvasWidth = 480;
                canvasHeight = 955;
            }

            const canvas = document.getElementById("js-shelf-sequence");
            const context = canvas.getContext("2d");

            canvas.width=canvasWidth;
            canvas.height=canvasHeight;
    
            const frameCount = 64;
            
            const currentFrame = index => (
            `/field-guide/dist/images/shelf-${device}/Shelf-${device}-${index.toString()}.jpg`
            )

            const images = {};
                
            let loadedImageCount = 0;

            for (let i = 1; i <= frameCount; i++) {
                const img = new Image();
                img.onload = function() {
                    if (++loadedImageCount === frameCount) {
                        context.drawImage(images[1], 0, 0);

                        window.addEventListener('scroll', () => {
                            const animationContainer = document.getElementById("js-sticky-shelf-animation");
                            const canvasLocation = canvas.getBoundingClientRect();
                            
                            if(Math.ceil(canvasLocation.top) <= 96) {
                                const scrollFraction = Math.ceil(animationContainer.clientHeight - animationContainer.getBoundingClientRect().bottom);
                                const frameIndex = Math.ceil(scrollFraction / 100);
                                
                                if(frameIndex <= 64 && frameIndex >= 1) {
                                    requestAnimationFrame(() => context.drawImage(images[frameIndex], 0, 0))
                                }
                            }
                        });
                    }
                };

                img.src = currentFrame(i);
                images[i] = img;
            }
        }
    }

    // Return
    return {
        scrubLactationSequencedImages: scrubLactationSequencedImages,
        scrubAnatomyOfMilk1SequencedImages : scrubAnatomyOfMilk1SequencedImages,
        scrubAnatomyOfMilk2SequencedImages : scrubAnatomyOfMilk2SequencedImages,
        scrubShelfSequencedImages : scrubShelfSequencedImages
    };
}());