var site = site || {};
site.VideoControls = (function () {
    // Functions

    var videoControl = function(){
        const videoControls = document.querySelectorAll(".js-control-bar");

        videoControls.forEach(function(videoCtrl, i) {

            const $this = videoCtrl;
            const $video = $this.previousElementSibling;
            const $playPauseBtn = $this.querySelectorAll('#playPauseBtn')[0];
            const $seekBar = $this.querySelectorAll('#seekBar')[0];
            const $progressBar = $this.querySelectorAll('#progressBar')[0];
            
            let updMinutes,
            updSeconds;
    
            var playPause = function () {
                if($video.paused) {
                    $video.play();
                    $playPauseBtn.title = 'Pause';
                    $playPauseBtn.querySelectorAll('[data-fa-i2svg]')[0].classList = 'fas fa-pause-circle';
                } else if ($video.ended) {
                    $video.currentTime = 0;
                    $video.play();
                    $playPauseBtn.title = 'Pause';
                } else {
                    $video.pause();
                    $playPauseBtn.title = 'Play';
                    $playPauseBtn.querySelectorAll('[data-fa-i2svg]')[0].classList = 'fas fa-play-circle';
                }
            };
    
            var videoEnd = function () {
                $playPauseBtn.title = 'Play';
                $playPauseBtn.querySelectorAll('[data-fa-i2svg]')[0].classList = 'fas fa-play-circle';
            };
    
            var videoTimeUpdate = function () {
                updMinutes = parseInt($video.currentTime / 60);
                updSeconds = parseInt($video.currentTime % 60);
                if (updMinutes < 10) {
                    updMinutes = "0" + updMinutes;
                }
            
                if (updSeconds < 10) {
                    updSeconds = "0" + updSeconds;
                }
            
                $seekBar.value = $video.currentTime * (100 / $video.duration);
                $progressBar.value = $seekBar.value;
            };
    
            var videoSeekBar = function () {
                $video.currentTime = $seekBar.value * ($video.duration / 100);
                videoTimeUpdate();
            };

            // Event Listeners
            //$video.addEventListener('loadedmetadata', customVideoInit);
            $video.addEventListener('click', playPause);
            $video.addEventListener('ended', videoEnd);
            $playPauseBtn.addEventListener('click', playPause);
            $video.addEventListener('timeupdate', videoTimeUpdate);
            $seekBar.addEventListener('change', videoSeekBar);
            $seekBar.addEventListener('input', videoSeekBar);

        });
    }

    // Return
    return {
        videoControl : videoControl
    };
}());