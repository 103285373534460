var site = site || {};
site.Gateway = (function () {
    // Functions

    var fadeAndShow = function($, mediaQueries){

        $('img.gateway-base').rwdImageMaps();

        $(document).on('click', '.gateway-container map area', function(e){
            e.preventDefault();

            $('.gateway-overlay-container').addClass('active'); 

            var imageID = $(this).attr('title').replace(" ", "").toLowerCase();

            $("#"+imageID).addClass('active');
            
            $(document).on('click', '.gateway-overlay-container', function(e){
                if(!$(e.target).hasClass('gateway-overlay')) {
                    $('.gateway-overlay-container').removeClass('active');
                    $('.gateway-overlay').removeClass('active');
                }
            })
        })
    }

    // Return
    return {
        fadeAndShow : fadeAndShow
    };
}());