const site = site || {};
site.Search = (() => {

    const toggleSearchBox = () => {
        const searchToggle = document.querySelector('.js-toggle-search');

        searchToggle.addEventListener('click', (event) => {
            event.preventDefault();
            
            const searchBox = searchToggle.previousElementSibling;
            const searchInput = searchBox.querySelector('.js-keyword');
            
            searchInput.focus();
        })
    }


    const searchResults = () => {
        const openTrigger = document.querySelectorAll('.js-submit-search');

        openTrigger.forEach((el, i) => {
            el.addEventListener('click', () => {
                
                const searchBox = el.previousElementSibling;
                const keyword = searchBox.value;
                const url = "/search-results?keyword=" + keyword;

                location.href = url;
            })
        });

        const keywordInputField = document.querySelectorAll('.js-keyword');

        keywordInputField.forEach((el, i) => {
            el.addEventListener('keyup', (e) => {
                if (e.keyCode == 13) {
                    const keyword = el.value;
                    const url = "/search-results?keyword=" + keyword;

                    location.href = url;
                }
            })
        });
    }

    const toggleAllVolumeResults = () => {
        const showToggleButtons = document.querySelectorAll('.js-show-toggle');

        showToggleButtons.forEach((showToggleButton) => {
            showToggleButton.addEventListener('click', (el) => {
                const button = el.target;
                const buttonSpan = button.querySelector('span');
                let buttonText = buttonSpan.innerHTML;

                if(buttonText.includes('More')) {
                    buttonText = buttonText.replace('More', 'Less');
                } else {
                    buttonText = buttonText.replace('Less', 'More');
                }
                
                buttonSpan.innerHTML = buttonText;

                const buttonIcon = button.querySelector('[data-fa-i2svg]');
                buttonIcon.classList.toggle('fa-plus');
                buttonIcon.classList.toggle('fa-minus');

                const parentOfButton = button.parentElement;
                
                const hiddenResultsText = parentOfButton.querySelector('.js-hidden-results');
                const allResultsText = parentOfButton.querySelector('.js-all-results');
                hiddenResultsText.classList.toggle('d-none');
                allResultsText.classList.toggle('d-none');

                const searchResults = parentOfButton.querySelectorAll('.js-search-result');
                searchResults.forEach((result) => {
                    if(result.classList.contains('js-open')) {
                        result.classList.add('d-none');
                        result.classList.remove('js-open');
                    } else {
                        if(result.classList.contains('d-none')) {
                            result.classList.remove('d-none');
                            result.classList.add('js-open');
                        }
                    }
                })
            })
        })
    }

    // Return
    return {
        toggleSearchBox: toggleSearchBox,
        searchResults: searchResults,
        toggleAllVolumeResults : toggleAllVolumeResults
    };
})();

