var site = site || {};
site.Utilities = (function () {
    // Functions

    var stickyPrimaryNav = function ($) {
        var header = {
			$window : $(window),
			mainDiv : $('.js-main'),
            theHeight : $('.js-header'),
            sidebarDiv : $('.js-sidebar')
        }

        header.mainDiv.css({paddingTop : header.theHeight.height()});
        header.sidebarDiv.css({top : header.theHeight.height()});
    }

    var nextSection = function ($) {
        $('.js-next-section').on('click', function(e){
            e.preventDefault();

            var thisRowParent = $(this).closest('.row');
            var nextRow = thisRowParent.next('.row');

            var scrollTop = (nextRow.offset().top - 40);

            $('html, body').animate({scrollTop: scrollTop}, 400);
        })
    }

    var getCheeseBytesDefinition = function($) {
        var cheeseBytes = $('.cheese-byte');

        cheeseBytes.each(function(){
            var cheeseByte = $(this);
            var cheeseByteTerm = cheeseByte.html();

            $.ajax({
                type: 'POST',
                url: '/chapter/searchcheesebytes',
                data: {
                    searchterm: cheeseByteTerm
                },
                dataType: 'json'
            })
            .done(function(data){
                cheeseByte.attr("data-original-title", cheeseByteTerm);
                cheeseByte.attr("data-content", data);
            })
        })
    }

    var eventTrackingForGTM = function($) {
        $(document).on('click', '.js-next-volume', function(){
            dataLayer.push({'event': 'nextVolume'});
        })

        $(document).on('click', '.js-next-chapter', function(){
            dataLayer.push({'event': 'nextChapter'});
        })

        $(document).on('click', '.js-nav-link', function(){
            dataLayer.push({'event': 'sidebarNav'});
        })

        $(document).on('click', '.js-welcome-back-link', function(){
            dataLayer.push({'event': 'welcomeBack'});
        })
    }

    // Return
    return {
        stickyPrimaryNav : stickyPrimaryNav,
        nextSection : nextSection,
        getCheeseBytesDefinition: getCheeseBytesDefinition,
        eventTrackingForGTM: eventTrackingForGTM
    };
}());