var site = site || {};
site.SlideNav = (function () {
    // Functions

    var toggleNav = function($){
        $('.js-toggle-nav').on('click', function(){
            $('.nav-sidebar').toggleClass('open-nav');
            $('.main-content').toggleClass('open-nav');
            $('.hamburger-bars').toggleClass('animate');
        })
        
    }

    // Return
    return {
        toggleNav : toggleNav
    };
}());