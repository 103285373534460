var site = site || {};
site.Offcanvas = (function () {
    // Functions

    var positionOffCanvasButton = function ($, mediaQueries) {
        setTimeout(() => {
            var offCanvasButtons = $('.js-offcanvas-btn');

            if(offCanvasButtons.length) {
                offCanvasButtons.each(function(){
                    const $this = $(this);
                    
                    const parentPrevSibling = $this.parent().prev();
                    

                    if(mediaQueries.md.matches){
                        

                        const thisWidth = $this.outerWidth();

                        const thisMiddle = thisWidth / 2;

                        $(window).on('load', function(){
                            shake($this);

                            $(window).on('scroll', function() {
                                const win = $(window);

                                const viewport = {
                                    top : win.scrollTop()
                                };

                                viewport.bottom = viewport.top + win.height();

                                const bounds = parentPrevSibling.offset();
                                bounds.bottom = bounds.top + parentPrevSibling.outerHeight();

                                if(viewport.bottom < bounds.top) {
                                    const parentPrevSiblingMiddle = parentPrevSibling.outerHeight() / 2;

                                    $this.css({'top': (bounds.top + parentPrevSiblingMiddle) + thisMiddle + "px"});
                                }


                                shake($this);
                            });
                        })

                        function shake($this) {
                            const thisBoundingBox = $this[0].getBoundingClientRect();
                            
                            if (thisBoundingBox.top >= 0 && thisBoundingBox.bottom <= (window.innerHeight || document.documentElement.clientHeight)){
                                if(!$this.hasClass('shake')){
                                    $this.addClass('shake');
                                }
                            } else {
                                if($this.hasClass('shake')){
                                    $this.removeClass('shake');
                                }
                            }
                        }
                    } else {
                        const parentPrevSiblingOffsetBottom = parentPrevSibling.offset().top + parentPrevSibling.outerHeight(true);
    
                        $(window).on('scroll', function() {
                            const windowBottom = $(window).scrollTop() + $(window).height();

                            if(windowBottom >= parentPrevSibling.offset().top + 50 && windowBottom <= parentPrevSiblingOffsetBottom + 10) {
                                $this.addClass('visible');
                            } else {
                                $this.removeClass('visible');
                            }
                        });
                    }
                })
            }
        }, 200);
    }

    var toggleOffCanvasButton = function ($) {
        $('.js-offcanvas-btn').on('click', function(){
            const $this = $(this);

            const parentPrevSibling = $this.parent().prev();
            const parentPrevSiblingOffsetTop = parentPrevSibling.offset().top;
            const parentPrevSiblingOffsetBottom = parentPrevSibling.offset().top + parentPrevSibling.outerHeight(true);

            $this.toggleClass('active');
            $this.next().toggleClass('active');

            $(window).on('scroll', function() {
                if($(window).scrollTop() <= parentPrevSiblingOffsetTop - 200 || $(window).scrollTop() >= parentPrevSiblingOffsetBottom - 300) {
                    $this.removeClass('active');
                    $this.next().removeClass('active');
                }
            });
        });

        $(document).on('click', function(e){
            if (!e.target.matches('.js-offcanvas-btn, .js-offcanvas-btn *, .offcanvas, .offcanvas *')) {
                var offCanvasButtons = $('.js-offcanvas-btn');

                if(offCanvasButtons.length) {
                    offCanvasButtons.each(function(){
                        const $this = $(this);

                        if($this.hasClass('active')) {
                            $this.removeClass('active');
                            $this.next().removeClass('active');
                        }
                    });
                }
            }
        })
    }

    // Return
    return {
        positionOffCanvasButton: positionOffCanvasButton,
        toggleOffCanvasButton: toggleOffCanvasButton
    };
}());