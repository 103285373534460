jQuery.noConflict();

(function($) {
    $(function(){
        // Bootstrap matching Media Queries
        //To use:
        //      Pass mediaQueries into module function: extranet.Utilities.function(mediaQueries)
        //      In module function:
        //          if(mediaQueries.sm.matches){
        //              code here
        //          }
        var mediaQueries = {
            xs: window.matchMedia('(min-width: 481px)'),
            sm: window.matchMedia('(min-width: 576px)'),
            md: window.matchMedia('(min-width: 768px)'),
            lg: window.matchMedia('(min-width: 992px)'),
            xl: window.matchMedia('(min-width: 1200px)')
        }

        //------------------------------------------------------------------------//
        //  Initialize Lazyloading with support for native lazyload
        //
        //  Defaults to use lazyload plugin, but will fall back to native support 
        //  for browsers with support for the loading="lazy" attribute
        //  (https://caniuse.com/#feat=loading-lazy-attr)
        //------------------------------------------------------------------------//
        var lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy",
            use_native: true 
        });

        var lazyLoadVideoInstance = new LazyLoad({
            elements_selector: ".lazy-video",
            threshold: -10,
            callback_enter: passVideoToGTM
        });

        function passVideoToGTM(el) {
            var videoSrc = el.getAttribute("data-src").split("/");
            var videoFileName = videoSrc.pop().replace('.mp4', '');
            dataLayer.push({'event': "videoPlay", 'videoFileName': videoFileName});
        }

        //------------------------------------------------------------------------//
        //  PUBLIC MODULE FUNCTIONS
        //------------------------------------------------------------------------//
        //  Usage Examples: 
        //      site.Utilities.functionName()
        //      site.Utilities.functionName(mediaQueries)
        //------------------------------------------------------------------------//
        site.Utilities.stickyPrimaryNav($, mediaQueries);
        site.Utilities.nextSection($);
        site.Utilities.getCheeseBytesDefinition($);
        site.Utilities.eventTrackingForGTM($);
        site.Assessment.startQuiz($);
        site.Assessment.submitQuiz($);
        site.SlideNav.toggleNav($);
        site.Carousel.carousel($);
        site.Carousel.carouselHalf($);
        site.Carousel.quizCarousel($);
        site.Carousel.timelineCarousel($);
        site.CheeseWheel.fadeAndShow($);
        site.Gateway.fadeAndShow($, mediaQueries);
        site.Offcanvas.positionOffCanvasButton($, mediaQueries);
        site.Offcanvas.toggleOffCanvasButton($);
        site.StickyScrubVideo.scrubLactationSequencedImages(mediaQueries);
        site.StickyScrubVideo.scrubAnatomyOfMilk1SequencedImages(mediaQueries);
        site.StickyScrubVideo.scrubAnatomyOfMilk2SequencedImages(mediaQueries);
        site.StickyScrubVideo.scrubShelfSequencedImages(mediaQueries);
        site.VideoControls.videoControl();
        site.Search.toggleSearchBox();
        site.Search.searchResults();
        site.Search.toggleAllVolumeResults();
        
        // Check DOM for images loaded via dynamically and update Lazyload instance
        if (lazyLoadInstance) {
            lazyLoadInstance.update();
        }

        if (lazyLoadVideoInstance) {
            lazyLoadVideoInstance.update();
        }

        $('[data-toggle="popover"]').popover({
            html: true
        });
        
        //------------------------------------------------------------------------//
        // WINDOW RESIZE FUNCTIONS
        //------------------------------------------------------------------------//
        window.addEventListener('resize', debounce(function() {
            //------------------------------------------------------------------------//
            //  Public module functions
            //------------------------------------------------------------------------//
            //  Usage Examples: 
            //      site.Utilities.functionName()
            //      site.Utilities.functionName(mediaQueries)
            //------------------------------------------------------------------------//
        }, 200));
    })
})(jQuery);