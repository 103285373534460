var site = site || {};
site.Assessment = (function () {
    let countdownTimer;

    // Functions

    var startQuiz = function($){
        $(document).on('click', '.js-start-quiz', function () {
            if($(this).attr('data-progress') == "False") {
                console.log('Field Guide Skipped');
                dataLayer.push({'event': 'Field Guide Skipped'});
            }

            $('.assessment-rules').fadeOut();
            $('.assessment-quiz').fadeIn();

            let timeleft = 30; // Time Length

            const $quizTimerMinutes = $('.quiz-timer .minutes');
            const $srQuizTimer = $('.js-sr-quiz-timer');
            const $timeoutModal = $('#timeoutModal');

            $quizTimerMinutes.html(timeleft + " " + (timeleft === 1 ? "min" : "mins"));
            $srQuizTimer.html(timeleft + " " + (timeleft === 1 ? "minute" : "minutes") + " remaining");

            if (!countdownTimer) {
                countdownTimer = setInterval(function () {
                    timeleft -= 1;

                    $quizTimerMinutes.html(timeleft + " " + (timeleft === 1 ? "min" : "mins"));
                    $srQuizTimer.html(timeleft + " " + (timeleft === 1 ? "minute" : "minutes") + " remaining");

                    if (timeleft <= 0) {
                        clearInterval(countdownTimer);
                        countdownTimer = null;

                        $(".swiper-button--quiz").remove();
                        $(".submit-quiz").remove();

                        $.ajax({
                            type: 'POST',
                            url: "/quiz/setquizprogress",
                            contentType: 'application/json; charset=utf-8',
                            data: JSON.stringify({ quizTaken: true, quizPassed: false }),
                            dataType: 'json'
                        });

                        $timeoutModal.modal('show');
                    }

                    if (timeleft !== 30 && (timeleft % 5 <= 0 || timeleft === 1)) {
                        $('.js-sr-time-announcement').html("You have " + timeleft + " " + (timeleft === 1 ? "minute" : "minutes") + " remaining");
                    }

                    if (timeleft <= 5) {
                        $('.quiz-timer').addClass('five-min-warning');
                    }
                }, 60000);
            }

            $(window).on('keypress', function (e) {
                var keycode = e.keyCode ? e.keyCode : e.which;
                if (keycode == '96') {
                    $srQuizTimer.show();

                    setTimeout(() => {
                        $srQuizTimer.hide();
                    }, 100);
                }
            });
        });
    }

    var submitQuiz = function($){
        $(document).on('click', '.js-submit-quiz', function(){
            $(this).addClass('btn-spinner');
            $(this).prop('disabled', true);
            
            clearInterval(countdownTimer);
            countdownTimer = null;

            dataLayer.push({'event': 'assessmentCompletion'});

            const questionOptions = $('.quiz-options');

            let questions = [];

            questionOptions.each(function(){
                const questionId = $(this).parent('.quiz-question').data('question-id');
                const userAnswer = $(this).find('input:checked').val();
                questions.push({QuestionId: questionId, SelectedAnswer: userAnswer}); 
            });

            $.ajax({
                type: 'POST',
                url: "/quiz/getquizscore",
                data: {
                    completedQuestions: questions
                },
                dataType: 'json',
                success: function(results) {
                    console.log(results);

                    let passQuiz = false;
                    const resultQuestions = results.questions;
                    const quizResultBody = $('.js-quiz-result-body');
                    const quizResultLink = $('.js-quiz-result-links');
                    const quizResultIncorrectAmount = $('.js-quiz-result-incorrect-amount');
                    const quizResultIncorrectAnswers = $('.js-quiz-result-incorrect-answers');

                    if(resultQuestions.length / questionOptions.length <= 0.2){
                        quizResultBody.html(`
                            <img class="w-50" src="/field-guide/dist/images/assessment/Congratulations.png" alt="">
                            <h2 class="js-quiz-result-headline h1 mt-3 font-weight-bold">Congratulations! You Passed!</h2>
                            <div class="mb-2"><a class="btn btn-accent-1 px-4 py-2 rounded-pill" href="${results.diplomaFilePath}" target="_blank">Download Your Certificate</a></div>
                            <div><a class="btn btn-link font-weight-light text-decoration-underline" href="/dashboard">Return to Dashboard</a></div>
                        `);
                        
                        dataLayer.push({'event': 'assessmentPassed'});
                        passQuiz = true;
                    }else {
                        quizResultBody.html(`
                            <h2 class="js-quiz-result-headline h1 mt-3 font-weight-bold">Better Luck Next Time!</h2>
                            <div><a class="btn btn-accent-1 px-4 py-2 rounded-pill" href="/dashboard">Return to Dashboard</a></div>
                        `);
                        quizResultLink.html(`
                            <div><a class="btn btn-accent-1 px-4 py-2 rounded-pill" href="/dashboard">Return to Dashboard</a></div>
                        `);

                        dataLayer.push({'event': 'assessmentFailed'});
                    }

                    if(resultQuestions.length > 0){
                        quizResultIncorrectAmount.html(`You Had ${resultQuestions.length} Incorrect Answers`);

                        resultQuestions.forEach(function (question, index) {
                            const SelectedKey = "Option" + question.selectedAnswer;
                            let optionNeverAnswered = "";
                            let optionA;
                            let optionB;
                            let optionC;
                            let optionD;

                            switch (SelectedKey) {
                                case "OptionA":
                                    optionA = question.optionA != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-bold rounded-pill wrong-answer"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option A - Wrong Answer">X</span><span>${question.optionA}</span></p>` : "";
                                    optionB = question.optionB != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option B">B</span><span>${question.optionB}</span></p>` : "";
                                    optionC = question.optionC != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option C">C</span><span>${question.optionC}</span></p>` : "";
                                    optionD = question.optionD != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option D">D</span><span>${question.optionD}</span></p>` : "";
                                    break;
                                case "OptionB":
                                    optionA = question.optionA != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option A">A</span><span>${question.optionA}</span></p>` : "";
                                    optionB = question.optionB != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-bold rounded-pill wrong-answer"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option B - Wrong Answer">B</span><span>${question.optionB}</span></p>` : "";
                                    optionC = question.optionC != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option C">C</span><span>${question.optionC}</span></p>` : "";
                                    optionD = question.optionD != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option D">D</span><span>${question.optionD}</span></p>` : "";
                                    break;
                                case "OptionC":
                                    optionA = question.optionA != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option A">A</span><span>${question.optionA}</span></p>` : "";
                                    optionB = question.optionB != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option B">B</span><span>${question.optionB}</span></p>` : "";
                                    optionC = question.optionC != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-bold rounded-pill wrong-answer"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option C - Wrong Answer">X</span><span>${question.optionC}</span></p>` : "";
                                    optionD = question.optionD != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option D">D</span><span>${question.optionD}</span></p>` : "";
                                    break;
                                case "OptionD":
                                    optionA = question.optionA != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option A">A</span><span>${question.optionA}</span></p>` : "";
                                    optionB = question.optionB != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option B">B</span><span>${question.optionB}</span></p>` : "";
                                    optionC = question.optionC != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option C">C</span><span>${question.optionC}</span></p>` : "";
                                    optionD = question.optionD != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-bold rounded-pill wrong-answer"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option D - Wrong Answer">X</span><span>${question.optionD}</span></p>` : "";
                                    break;    
                                default:
                                    optionNeverAnswered = `<p class="d-flex align-items-center mb-1 p-3 font-weight-bold rounded-pill wrong-answer">Never Answered</p>`;
                                    optionA = question.optionA != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option A">A</span><span>${question.optionA}</span></p>` : "";
                                    optionB = question.optionB != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option B">B</span><span>${question.optionB}</span></p>` : "";
                                    optionC = question.optionC != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option C">C</span><span>${question.optionC}</span></p>` : "";
                                    optionD = question.optionD != "" ? `<p class="d-flex align-items-center mb-1 p-3 font-weight-normal rounded-pill"><span class="mr-2 pr-2 border-right border-accent-1" aria-label="Option D">D</span><span>${question.optionD}</span></p>` : "";
                                    break;
                            }

                            quizResultIncorrectAnswers.append(`
                                <div class="mb-5">
                                    <div class="quiz-results--question border-bottom border-gray-700">
                                        <p class="h3 mb-3 font-family-base font-weight-bold">${question.question}</p>
                                    </div>
                                    <div class="quiz-results--options d-flex flex-column py-4">
                                        ${optionNeverAnswered}
                                        ${optionA}
                                        ${optionB}
                                        ${optionC}
                                        ${optionD}
                                    </div>
                                </div>
                            `);
                        });
                    }else {
                        quizResultIncorrectAmount.html(`You Answered All Questions Correct!`);
                    }

                    $('.assessment-quiz').fadeOut();

                    if(passQuiz){
                        var audio = document.getElementById("audio");
                        audio.play();
                    }
                    
                    $('.assessment-results').fadeIn();
                },
                error: function (xhr, customErrorMessage) {
                    console.log(xhr.status + ": "+ customErrorMessage);
                }
            });
        });
    }

    // Return
    return {
        startQuiz : startQuiz,
        submitQuiz : submitQuiz
    };
}());