var site = site || {};
site.Carousel = (function () {
    // Functions

    var carousel = function($){
        if($('.js-carousel-full').length){
            $('.js-carousel-full').each(function(){
                var $this = this;
                var prevBtn = $(this).prev()[0];
                var nextBtn = $(this).next()[0];

                var carousel = new Swiper($this, {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: nextBtn,
                        prevEl: prevBtn,
                    },
                    breakpoints: {
                        992: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                        }
                    },
                })
            })
        }
    }

    var carouselHalf = function($){
        if($('.js-carousel-half').length){
            $('.js-carousel-half').each(function(){
                var $this = this;
                var prevBtn = $(this).prev()[0];
                var nextBtn = $(this).next()[0];

                var carousel = new Swiper($this, {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: nextBtn,
                        prevEl: prevBtn,
                    }
                })
            })
        }
    }

    var quizCarousel = function($) {
        if($('.js-quiz-carousel').length){
            $('.js-quiz-carousel').each(function(){
                var $this = $(this);

                var carousel = new Swiper(this, {
                    slidesPerView: 1,
                    allowTouchMove: false,
                    navigation: {
                        nextEl: ".swiper-quiz-btn-next",
                        prevEl: ".swiper-quiz-btn-prev",
                    },
                    on: {
                        progress: function(){
                            $(".swiper-quiz-btn-next")
                                .addClass("swiper-button-disabled")
                                .attr("disabled", "")
                                .attr("aria-disabled", "true");
                        }                        
                    }
                })

                $("input[name='question1']").on('change', function(){
                    $(".swiper-quiz-btn-next")
                        .removeClass("swiper-button-disabled")
                        .removeAttr("disabled")
                        .attr("aria-disabled", "false");
                })

                $this.on('keyup', function(e){                            
                    if(e.keyCode == 9 && !e.shiftKey){
                        var focusedElement = document.activeElement;
        
                        if(focusedElement.getBoundingClientRect().right > window.innerWidth - 30) {
                            carousel.slideNext();
                        }
                    }
        
                    if(e.shiftKey && e.keyCode == 9) {
                        var focusedElement = document.activeElement;
        
                        if(focusedElement.getBoundingClientRect().left < 30) {
                            carousel.slidePrev();
                        }
                    }
                })

                carousel.on('slideChange', function () {
                    const carouselIndex = carousel.activeIndex + 1;

                    if($("input[name='question"+carouselIndex+"']:checked").length > 0) {
                        $(".swiper-quiz-btn-next")
                            .removeClass("swiper-button-disabled")
                            .removeAttr("disabled")
                            .attr("aria-disabled", "false");
                    } else {
                        $("input[name='question"+carouselIndex+"']").on('change', function(){
                            $(".swiper-quiz-btn-next")
                                .removeClass("swiper-button-disabled")
                                .removeAttr("disabled")
                                .attr("aria-disabled", "false");
                            
                        })
                    }

                    // Check for last slide and if yes, show form submit button
                    if(carousel.isEnd){
                        $(".swiper-quiz-btn-next").hide();

                        if($("input[name='question"+carouselIndex+"']:checked").length > 0) {
                            $('.js-submit-quiz')
                                .show()
                                .removeAttr("disabled");
                        } else {
                            $('.js-submit-quiz')
                                .show()
                                .attr("disabled", "");
                            
                                $("input[name='question"+carouselIndex+"']").on('change', function(){
                                    $('.js-submit-quiz')
                                        .show()
                                        .removeAttr("disabled");
                            })
                        }
                    }else{
                        $(".swiper-quiz-btn-next").show();
                        $('.js-submit-quiz').hide();
                    }
                });                
            })
        }
    }

    var timelineCarousel = function($){
        var swiper = new Swiper(".js-timeline-slider-nav", {
            slidesPerView: 3,
            spaceBetween: 10,
            centeredSlides: true,
            mousewheel: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                992: {
                    slidesPerView: 5,
                    direction: "vertical",
                }
            },
        });

        var swiper2 = new Swiper(".js-timeline-slider-for", {
            spaceBetween: 10,
            centeredSlides: true,
            thumbs: {
                swiper: swiper,
            },
        });

        swiper.on('click', function () {
            const swiperClickedIndex = swiper.clickedIndex;
            swiper.slideTo(swiperClickedIndex, 500);
        });

        swiper.on('slideChange', function () {
            const swiperIndex = swiper.activeIndex;
            swiper2.slideTo(swiperIndex, 500);
        });

        swiper2.on('slideChange', function(){
            const swiperIndex = swiper2.activeIndex;
            swiper.slideTo(swiperIndex, 500);
        });
    }

    // Return
    return {
        carousel : carousel,
        carouselHalf : carouselHalf,
        quizCarousel : quizCarousel,
        timelineCarousel : timelineCarousel
    };
}());